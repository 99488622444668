.react-tel-input .flag-dropdown {
  position: absolute;
  top: 2px;
  bottom: 0;
  padding: 0;
  left: 4px;
  background-color: white;
  border: none;
  border-radius: 0;
  height: 48px;
}
